import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl, selectLoginUrl, selectLogoutUrl } from 'core/selectors/app';
import { selectUserName, selectUserIsIdentified } from 'core/selectors/user';
import { selectActiveMQ } from 'core/modules/MediaQueries/selectors';
import { useDropdown } from 'core/hooks/useDropdown';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import { selectAccountSummaryData } from 'core/modules/AccountSummary/selectors';
import AccountSummary from 'core/modules/AccountSummary/AccountSummary';

export function UserInfoSectionWithDropdown() {
  const siteUrl = useSelector(selectSiteUrl);
  const loginUrl = useSelector(selectLoginUrl);
  const logoutUrl = useSelector(selectLogoutUrl);
  const userName = useSelector(selectUserName);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const { lifetimeEarningValue } = useSelector(selectAccountSummaryData);
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();

  const activeMQ = useSelector(selectActiveMQ);

  const renderMessage = ({ lifetimeEarningFormatted }) => (
    <span>AAdvantage&reg; eShopping&#8480; miles: <strong>{lifetimeEarningFormatted || 0}</strong></span>
  );

  if (!userIsIdentified) {
    return (
      <div className="mn_brandLinks">
        <a className="mn_button mn_loginLink" href={loginUrl}>Log in</a>
      </div>
    );
  }

  if (['XL', 'XXL'].includes(activeMQ)) {
    return (
      <div
        onMouseEnter={showSubnav}
        onMouseLeave={hideSubnav}
        onBlur={hideSubnav}
        className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
        data-section-name="Account"
      >
        <div className="mn_userInfo">
          <span className="mn_userNameWrap">Hello, <span className="mn_userName">{userName}</span></span>
          <span className="mn_userInfoWrap"><AccountSummary renderData={data => (renderMessage(data))} /></span>
        </div>
        <button
          className="mn_navigationItem"
          aria-expanded={isFocused}
          aria-label="Account"
          onFocus={showSubnav}
        >
          <i className="mn_aaSvgIcon mn_aaIconMyAccount" aria-hidden="true" />
        </button>
        <div className="mn_navigationDropdown">
          <ul>
            <li><a href={`${siteUrl}/ai____.htm`}>My account</a></li>
            <li><a href={`${siteUrl}/foryou____.htm`}>Offers for you</a></li>
            <li><a href={logoutUrl}>Log out</a></li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="mn_navigationDropdownTrigger" data-section-name="Account">
      <div className="mn_userInfo">
        <span className="mn_userNameWrap">Hello, <span className="mn_userName">{userName}</span></span>
        <ShowOnMQ mobile>
          <span className="mn_userInfoWrap">AAdvantage&reg; eShopping&#8480; miles: {lifetimeEarningValue}</span>
        </ShowOnMQ>
        <ShowOnMQ m l>
          <span className="mn_userInfoWrap"><AccountSummary renderData={data => (renderMessage(data))} /></span>
        </ShowOnMQ>
      </div>
      <a href={`${siteUrl}/ai____.htm`} className="mn_navigationItem" title="Opens my account page">
        <i className="mn_aaSvgIcon mn_aaIconMyAccount" aria-hidden="true" />
      </a>
    </div>
  );
}

export default UserInfoSectionWithDropdown;
