import { createSelector } from 'reselect';

const selectCreditCardMarketingBanner = state => state.creditCardMarketingBanner;

export const selectCreditCardMarketingBannerByNamespace = createSelector(
  [
    state => state.creditCardMarketingBanner,
    (state, namespace = 'default') => namespace,
  ],
  (creditCardMarketingBanner, namespace) => {
    const { defaults = {} } = creditCardMarketingBanner;
    return creditCardMarketingBanner[`namespace-${namespace}`] || defaults;
  },
);

export const selectCreditCardMarketingBannerConfig = createSelector(
  selectCreditCardMarketingBanner,
  creditCardMarketingBanner => creditCardMarketingBanner.config,
);

export const makeSelectCreditCardMarketingBannerPlacement = () => (
  createSelector(
    selectCreditCardMarketingBannerByNamespace,
    ({ placement }) => placement,
  )
);

export const makeSelectCreditCardMarketingBannerIsLoaded = () => (
  createSelector(
    selectCreditCardMarketingBannerByNamespace,
    ({ isLoaded }) => isLoaded,
  )
);

export const makeSelectCreditCardMarketingBannerIsLoading = () => (
  createSelector(
    selectCreditCardMarketingBannerByNamespace,
    ({ isLoading }) => isLoading,
  )
);
