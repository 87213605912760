import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUserIsIdentified } from 'core/selectors/user';

import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import SearchBar from 'core/modules/SearchBar/SearchBar';
import UserInfoSectionWithDropdown from './components/UserInfoSectionWithDropdown/UserInfoSectionWithDropdown';
import HelpSectionWithDropdown from './components/HelpSectionWithDropdown/HelpSectionWithDropdown';
import ExtraRewardsSectionWithDropdown
  from './components/ExtraRewardsSectionWithDropdown/ExtraRewardsSectionWithDropdown';
import FavoritesSection from './components/FavoritesSection/FavoritesSection';
import SearchSection from './components/SearchSection/SearchSection';

import './AccountNavigation.scss';

function AccountNavigation({ closeSideBar }) {
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const clickHandler = (event) => {
    setIsSearchExpanded(true);
    closeSideBar(event);
  };

  const getFocusWasRemoved = () => {
    isSearchExpanded && setIsSearchExpanded(false);
  };

  if (isSearchExpanded) {
    return (
      <div className="mn_secondaryNavSearchBar">
        <SearchBar
          hasFocusOnInit
          getFocusWasRemoved={getFocusWasRemoved}
        />
      </div>
    );
  }

  return (
    <nav
      className={`mn_accountNavigation ${userIsIdentified ? 'mn_isIdentified' : 'mn_isAnonymous' }`}
      aria-label="Secondary navigation"
    >
      <ul className="mn_navigationWrap">
        <li className="mn_userSection">
          <UserInfoSectionWithDropdown />
        </li>
        <ShowOnMQ xl xxl>
          <li className="mn_helpSection">
            <HelpSectionWithDropdown />
          </li>
        </ShowOnMQ>
        <li className="mn_headerExtraRewardsSection">
          <ExtraRewardsSectionWithDropdown />
        </li>
        <li className="mn_favoritesSection">
          <FavoritesSection />
        </li>
        <ShowOnMQ m l>
          <li className="mn_searchSection">
            <SearchSection clickHandler={clickHandler} isSearchExpanded={isSearchExpanded} />
          </li>
        </ShowOnMQ>
      </ul>
    </nav>
  );
}

export default AccountNavigation;
