import { put, select } from 'redux-saga/effects';
import { selectUserIsIdentified } from 'core/selectors/user';
import { fetchFavorites } from 'core/actions/member';

export default function* favoritesSaga() {
  const userIsIdentified = yield select(selectUserIsIdentified);
  if (userIsIdentified) {
    yield put(fetchFavorites());
  }
}
