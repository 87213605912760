import React from 'react';

import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import SearchBar from 'core/modules/SearchBar/SearchBar';
import NavigationTOF from 'org/modules/NavigationTOF/NavigationTOF';
import SidebarTrigger from '../SidebarTrigger/SidebarTrigger';
import MainLogo from '../MainLogo/MainLogo';
import SearchBarTrigger from '../SearchBarTrigger/SearchBarTrigger';
import AccountNavigation from '../AccountNavigation/AccountNavigation';
import SkipHeader from '../SkipHeader/SkipHeader';

import './HeaderMainBar.scss';

function HeaderMainBar({
  sidebarTriggerClickHandler, toggleSearchBar, isSearchBarOpen, setRef, isSidebarOpen, closeSideBar,
}) {
  return (
    <div className="mn_headerMainBar" ref={setRef}>
      <SkipHeader />
      <div className="mn_contentSection">
        <ShowOnMQ mobile>
          <SearchBarTrigger
            ariaExpanded={isSearchBarOpen}
            onClick={toggleSearchBar}
          />
        </ShowOnMQ>
        <MainLogo />
        <ShowOnMQ xl xxl>
          <div className="mn_searchAndNavWrap">
            <NavigationTOF />
            <SearchBar />
          </div>
        </ShowOnMQ>
        <div className="mn_navigationButtonsWrap">
          <ShowOnMQ tablet desktop>
            <AccountNavigation closeSideBar={closeSideBar} />
          </ShowOnMQ>
          <ShowOnMQ mobile tablet l>
            <SidebarTrigger isSidebarOpen={isSidebarOpen} sidebarTriggerClickHandler={sidebarTriggerClickHandler} />
          </ShowOnMQ>
        </div>
      </div>
    </div>
  );
}

export default HeaderMainBar;
