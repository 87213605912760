import React from 'react';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';

import Navigation from 'org/modules/Navigation/Navigation';
import SidebarWelcomeMessage from '../SidebarWelcomeMessage/SidebarWelcomeMessage';

import './HeaderSecondaryBar.scss';

function HeaderSecondaryBar({ sidebarTriggerClickHandler }) {
  return (
    <ShowOnMQ mobile tablet l>
      <div className="mn_headerSecondaryBar">
        <SidebarWelcomeMessage sidebarTriggerClickHandler={sidebarTriggerClickHandler} />
        <div className="mn_divider" />
        <Navigation />
      </div>
    </ShowOnMQ>
  );
}

export default HeaderSecondaryBar;
