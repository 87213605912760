import React from 'react';

import './SearchBarTrigger.scss';

function SearchBarTrigger({ ariaExpanded, onClick }) {
  return (
    <div className="mn_searchTriggerWrapper">
      <button
        className="mn_searchTrigger mn_aaSvgIcon mn_aaIconSearchSvg"
        aria-label="Display search field"
        onClick={onClick}
        aria-expanded={ariaExpanded}
      />
    </div>
  );
}

export default SearchBarTrigger;
