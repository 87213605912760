import unionBy from 'lodash/unionBy';
import coreGaActionEvents, { coreGaClickAndHoverEvents } from 'core/utils/analytics/ga-events';

const gaActionEvents = {
  ...coreGaActionEvents,
};

const orgGaClickAndHoverEvents = [
];

/**
 * Combine org-specific and core click-and-hover events. Please note that the
 * unionBy lodash method will override a core event if they have the same
 * @type {EventTrackerList}
 */
export const gaClickAndHoverEvents = unionBy(orgGaClickAndHoverEvents, coreGaClickAndHoverEvents, 'selector');

export default gaActionEvents;
