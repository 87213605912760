import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';

export function FavoritesSection() {
  const siteUrl = useSelector(selectSiteUrl);

  return (
    <a
      className="mn_navigationItem"
      href={`${siteUrl}/favorites____.htm`}
      title="Opens favorites page"
    >
      <i className="mn_aaSvgIcon mn_aaIconFavorite" aria-hidden="true" />
    </a>
  );
}
export default FavoritesSection;
