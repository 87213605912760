import React from 'react';

import ChromeRating from 'core/modules/Partials/ChromeRating/ChromeRating';

function ButtonScrollingModalContent({
  redirectToChromeStore, renderedLogos, storeCount, buttonContentRef,
}) {
  return (
    <div className="mn_buttonContent" ref={buttonContentRef}>
      <div className="mn_buttonBlock mn_buttonBlockOne">
        <div className="mn_motto">The AAdvantage&reg; eShopping<sup>SM</sup> Button</div>
        <h2>Earning miles couldn't be easier</h2>
        <p>
          On average, members who use the AAdvantage&reg; eShopping<sup>SM</sup> button browser extension earn<br />
          <strong>
            twice as many American Airlines AAdvantage&reg; miles
          </strong> miles as members who do not use the button
        </p>
        <button className="mn_button" data-number="1" onClick={redirectToChromeStore}>Add to browser</button>
        <ChromeRating chromeText="Chrome Web Store rating" />
      </div>
      <div className="mn_buttonBlock mn_buttonBlockTwo">
        <div className="mn_buttonOverlayMerchantLogosWrap">
          <div className="mn_buttonOverlayMerchantLogos">
            {renderedLogos}
          </div>
        </div>
        <div className="mn_motto">Never miss a mile with the button</div>
        <h2>Earn miles at {storeCount}+ stores with the button</h2>
        <p>
          Notifications and automatic coupon alerts through the AAdvantage&reg; eShopping<sup>SM</sup> button<br />
          help you save money and earn AAdvantage&reg; miles on the shopping you're already doing
        </p>
        <button className="mn_button" data-number="2" onClick={redirectToChromeStore}>Discover the button</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockThree">
        <h2>How it works</h2>
        <div className="mn_hiwHints">
          <div>
            <h3>Install in seconds</h3>
            <p>Download the free browser extension in just two clicks</p>
          </div>
          <div>
            <h3>Shop as usual</h3>
            <p>
              Shop at {storeCount}+ stores &#8212; the button will notify you when AAdvantage&reg; miles are available
            </p>
          </div>
          <div>
            <h3>Earn miles</h3>
            <p>Click the notification to activate your earnings and rack up AAdvantage&reg; miles</p>
          </div>
        </div>
        <button className="mn_button" data-number="3" onClick={redirectToChromeStore}>Install now</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockFour">
        <h2>Get the best price & the most miles</h2>
        <p>
          Get notified to activate AAdvantage&reg; miles while you shop and receive alerts when a<br />
          lower price is available. Plus, the button automatically applies coupons directly to your cart
        </p>
        <button className="mn_button" data-number="4" onClick={redirectToChromeStore}>Start earning more</button>
        <ChromeRating chromeText="Chrome Web Store rating" />
      </div>
    </div>
  );
}

export default ButtonScrollingModalContent;
