import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import { setHasPreviouslyLoggedIn } from 'core/utils/helpers/hasPreviouslyLoggedIn';
import { scrollToElement } from 'core/utils/helpers/scrollToElement';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import {
  selectSiteUrl, selectPrivacyPolicyUrl, selectTermsAndConditionsURL,
  selectToolbarConfig, selectPrivacyLinkEnabled, selectDataPrivacyPageEnabled,
  selectClientHostedDPSRedirectURL, selectBrandGdprDisplayName,
} from 'core/selectors/app';
import { selectUserIsIdentified, selectBrowserIsMobile } from 'core/selectors/user';

import CreditCardMarketingBanner from 'core/modules/CreditCardMarketingBanner/CreditCardMarketingBanner';

import config from './configs';
import './Footer.scss';

function Footer() {
  const isMobileMQ = useSelector(selectIsMobileMQ);
  const siteUrl = useSelector(selectSiteUrl);
  const isMobile = useSelector(selectBrowserIsMobile);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const privacyPolicyUrl = useSelector(selectPrivacyPolicyUrl);
  const brandGdprDisplayName = useSelector(selectBrandGdprDisplayName);
  const enablePrivacyNoticeLink = useSelector(selectPrivacyLinkEnabled);
  const enableDonotSharePage = useSelector(selectDataPrivacyPageEnabled);
  const termsAndConditionsURL = useSelector(selectTermsAndConditionsURL);
  const clientHostedDPSRedirectURL = useSelector(selectClientHostedDPSRedirectURL);
  const { enableButtonMarketing } = useSelector(selectToolbarConfig) || {};
  const { checkShouldShowBackButtonInMobileFooter } = config;

  useEffect(() => {
    // Set hasPreviouslyLoggedIn flag in Local Storage if user is loggedin
    userIsIdentified && setHasPreviouslyLoggedIn();
  }, []);

  const scrollToTop = () => scrollToElement(document.body, 1000);
  const shouldShowButtonLink = !isMobile && enableButtonMarketing;
  const shouldShowBackButtonInMobileFooter = checkShouldShowBackButtonInMobileFooter(userIsIdentified);

  const renderDataPrivacySettingsPage = () => {
    if (!userIsIdentified) {
      return (<li><a href={`${siteUrl}/dps____.htm`} title="Opens data privacy page">Privacy Preferences</a></li>);
    }
    if (clientHostedDPSRedirectURL) {
      return (
        <li>
          <a href={`${clientHostedDPSRedirectURL}/dps____.htm`} title="Opens a new window">Privacy Preferences</a>
        </li>
      );
    }
    return null;
  };

  const renderDesktopFooter = () => (
    <footer className="mn_brandFooter">
      <div className="mn_brandFooterInner mn_contentWrap">
        <nav className="mn_footerNav" aria-label="help links">
          <ul>
            <li><a href={`${siteUrl}/hiw____.htm`}>How it works</a></li>
            <li><a href={`${siteUrl}/faq____.htm`}>Help</a></li>
            <li>
              <a
                href="https://www.aa.com"
                className="mn_externalLink"
                {...addExternalAttributesIfNeeded('https://www.aa.com', siteUrl)}
              >
                aa.com
              </a>
            </li>
            <li><a href={`${siteUrl}/contact____.htm`}>Contact us</a></li>
            { shouldShowButtonLink &&
              <li><a href={`${siteUrl}/button`} className="mn_buttonFooterLink">Get the button</a></li>
            }
            <li>
              <a
                href={privacyPolicyUrl}
                {...addExternalAttributesIfNeeded(privacyPolicyUrl, siteUrl)}
              >
                Privacy policy
              </a>
            </li>
            <li><a href={termsAndConditionsURL}>Terms</a></li>
            {enablePrivacyNoticeLink &&
              <li>
                <a href={`${siteUrl}/p/privacy-notice.htm`}>{brandGdprDisplayName} terms and privacy notice</a>
              </li>
            }
            {enableDonotSharePage && renderDataPrivacySettingsPage()}
          </ul>
        </nav>
        <div className="mn_footerRightSide">
          <CreditCardMarketingBanner />
        </div>
      </div>
    </footer>
  );

  const renderMobileFooter = () => (
    <footer className="mn_brandFooter">
      {shouldShowBackButtonInMobileFooter &&
        <div className="mn_backToTopButtonWrap">
          <button className="mn_backToTopButton" onClick={scrollToTop}>Back to top</button>
        </div>
      }
    </footer>
  );

  return (
    isMobileMQ ? renderMobileFooter() : renderDesktopFooter()
  );
}

export default Footer;
