import {
  CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS, CREDIT_CARD_MARKETING_BANNER_GET_FAILURE,
} from 'core/modules/CreditCardMarketingBanner/actions/types';
import config from 'core/modules/CreditCardMarketingBanner/configs';

const initialState = {
  config,
  defaults: {
    isLoaded: false,
    isLoading: false,
    placement: [],
  },
};

const creditCardMarketingBannerParser = (response = []) => {
  const CONTENT_ITEM_TYPE_WITH_TEXT = 59;
  const CONTENT_ITEM_TYPE_WITH_IMAGE = 40;

  const textContent = response.find(elem => (
    elem.contentItemTypeId === CONTENT_ITEM_TYPE_WITH_TEXT
  ));

  const imageContent = response.find(elem => (
    elem.contentItemTypeId === CONTENT_ITEM_TYPE_WITH_IMAGE
  ));

  if (!textContent || !imageContent) {
    return {};
  }

  const {
    clickUrl,
    assets: {
      text, callToActionText, rolloverCopy, shortSummaryText,
    },
  } = textContent;

  const { assets: { image, imageAlternateText } } = imageContent;

  return {
    clickUrl,
    text,
    callToActionText,
    rolloverCopy,
    shortSummaryText,
    image,
    imageAlternateText,
  };
};

const creditCardMarketingBanner = (state = initialState, action) => {
  const namespaceId = action?.options?.namespace || action?.context?.options?.namespace || 'default';
  const namespace = `namespace-${namespaceId}`;
  const { defaults } = state;
  const { [namespace]: namespaceState = defaults } = state;
  switch (action.type) {
    case CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS: {
      const { response } = action.payload;
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          placement: creditCardMarketingBannerParser(response),
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    case CREDIT_CARD_MARKETING_BANNER_GET_FAILURE: {
      return {
        ...state,
        [namespace]: {
          ...namespaceState,
          placement: [],
          isLoading: false,
          isLoaded: true,
        },
      };
    }
    default: {
      const { options = {} } = action;
      if (options.success === CREDIT_CARD_MARKETING_BANNER_GET_SUCCESS) {
        return {
          ...state,
          [namespace]: {
            ...namespaceState,
            isLoading: true,
          },
        };
      }

      return state;
    }
  }
};

export default creditCardMarketingBanner;
