import { all, call } from 'redux-saga/effects';
import values from 'lodash/values';
import coreSagas from 'core/sagas';
import searchHybridFormSaga from 'core/modules/HybridSearchForm/sagas';
import emailOptInSubscribeModalSaga from 'core/modules/Modal/components/EmailOptInSubscribeModal/sagas';
import emailOptInSuccessModalSaga from 'core/modules/Modal/components/EmailOptInSuccessModal/sagas';
import editEmailPreferencesModalSaga from 'core/modules/Modal/components/EditEmailPreferencesModal/sagas';
import favoritesAlertModalSaga from 'core/modules/Modal/components/FavoritesAlertModal/sagas';
import searchModalSaga from 'core/modules/Modal/components/SearchModal/sagas';
import hiwVideoModal from 'core/modules/Modal/components/HiwVideoModal/sagas';
import deletedMemberModalSaga from 'core/modules/Modal/components/DeletedMemberModal/sagas';
import productDetailsModalSaga from 'core/modules/Modal/components/ProductDetailsModal/sagas';
import favoritesSaga from './favorites';

const combinedSagas = {
  ...coreSagas,
  searchHybridFormSaga,
  emailOptInSubscribeModalSaga,
  emailOptInSuccessModalSaga,
  favoritesSaga,
  editEmailPreferencesModalSaga,
  favoritesAlertModalSaga,
  searchModalSaga,
  hiwVideoModal,
  deletedMemberModalSaga,
  productDetailsModalSaga,
};

export default function* rootSaga() {
  yield all(values(combinedSagas).map(saga => call(saga)));
}
