import React from 'react';
import Loadable from 'react-loadable';
import { useLocation } from 'react-router-dom';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

export const routesConfig = [
  { route: '/h____.htm?', PageComponent: LoadableWrapper('/Home') },
  { route: '/refer-a-friend____.htm', PageComponent: LoadableWrapper('/Referral') },
  { route: '/index.php', PageComponent: Index },
  { route: '/sr____.htm', PageComponent: LoadableWrapper('/ProductSearchResults') },
  { route: '/hsr____.htm', PageComponent: LoadableWrapper('/HybridSearchResults') },
  { route: '/msr____.htm', PageComponent: LoadableWrapper('/StoresSearchResults') },
  { route: '/csr____.htm', PageComponent: LoadableWrapper('/CouponSearchResults') },
  { route: '/button/safari/tutorial', PageComponent: LoadableWrapper('/SafariButtonInstallTutorial') },
];

// Route to the correct page if is passed as a query parameter (e.g. /index.php?p=h)
function Index() {
  const location = useLocation();
  const queryParams = getParamsFromSearch(location.search);
  const { p: pageName } = queryParams;

  switch (pageName) {
    case 'h': {
      const Home = LoadableWrapper('/Home');
      return <Home />;
    }
    default: {
      return null;
    }
  }
}
