import { useSelector } from 'react-redux';
import { selectCardBarclaysIndicator, selectCardCitiIndicator } from 'org/selectors/user';
import { selectCreditCardMarketingBannerConfig } from '../selectors';

export const useDefineContentGroupIds = () => {
  const config = useSelector(selectCreditCardMarketingBannerConfig);
  const hasCardCitiIndicator = useSelector(selectCardCitiIndicator);
  const hasCardBarclaysIndicator = useSelector(selectCardBarclaysIndicator);

  const {
    api: { params: { content_group_id: defaultContentGroupId } },
    cardContentGroupIds: { cityCardContentGroupId, barclaysCardContentGroupId, cityAndBarclaysCardContentGroupId },
  } = config;

  const defineContentGroupIds = () => {
    if (hasCardCitiIndicator && hasCardBarclaysIndicator) {
      return [cityAndBarclaysCardContentGroupId, defaultContentGroupId];
    }

    if (hasCardCitiIndicator) {
      return [cityCardContentGroupId, defaultContentGroupId];
    }

    if (hasCardBarclaysIndicator) {
      return [barclaysCardContentGroupId, defaultContentGroupId];
    }

    return [defaultContentGroupId];
  };

  const contentGroupIds = defineContentGroupIds();

  return {
    contentGroupIds,
  };
};
