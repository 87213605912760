import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';
import { useDropdown } from 'core/hooks/useDropdown';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';

export function HelpSectionWithDropdown() {
  const siteUrl = useSelector(selectSiteUrl);
  const {
    hideSubnav, showSubnav, isFocused, dropdownClassName,
  } = useDropdown();

  return (
    <div
      className={`mn_navigationDropdownTrigger ${dropdownClassName}`}
      data-section-name="FAQ"
      onMouseEnter={showSubnav}
      onMouseLeave={hideSubnav}
      onBlur={hideSubnav}
    >
      <button
        onFocus={showSubnav}
        className="mn_navigationItem"
        aria-expanded={isFocused}
        aria-label="FAQ"
      >
        <i className="mn_aaSvgIcon mn_aaIconHelp" aria-hidden="true" />
      </button>
      <div className="mn_navigationDropdown">
        <ul>
          <li><a href={`${siteUrl}/hiw____.htm`}>How it works</a></li>
          <li><a href={`${siteUrl}/faq____.htm`}>FAQs</a></li>
          <li><a href={`${siteUrl}/contact____.htm`}>Contact us</a></li>
          <li>
            <a
              href="https://www.aa.com"
              className="mn_externalLink"
              {...addExternalAttributesIfNeeded('https://www.aa.com', siteUrl)}
            >
              aa.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HelpSectionWithDropdown;
