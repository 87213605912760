import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SessionStorageCache from 'core/utils/cache/sessionStorage';
import { selectSsoMigrationSiteNotice } from './selectors';

import './SsoMigrationSiteNotice.scss';

function SsoMigrationSiteNotice({ setRef }) {
  const ssoMigrationSiteNotice = useSelector(selectSsoMigrationSiteNotice);
  const [isSiteNoticeOpen, setSiteNoticeOpen] = useState(!!ssoMigrationSiteNotice);
  const sessionStorageCache = new SessionStorageCache();
  const isDismissedSsoSiteNotice = sessionStorageCache.getItem('isDismissedSsoSiteNotice');

  const closeHandler = (event) => {
    event.preventDefault();
    setSiteNoticeOpen(false);
    sessionStorageCache.setItem('isDismissedSsoSiteNotice', true);
  };

  if (!isSiteNoticeOpen || isDismissedSsoSiteNotice) {
    return null;
  }

  return (
    <div className="mn_ssoMigrationSiteNotice" ref={setRef}>
      <p dangerouslySetInnerHTML={{ __html: ssoMigrationSiteNotice }} />
      <button
        className="mn_siteNoticeClose"
        aria-label="Close site notice"
        onClick={closeHandler}
      />
    </div>
  );
}

export default SsoMigrationSiteNotice;
