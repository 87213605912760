import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserName, selectUserIsIdentified, selectDisplayableUserId } from 'core/selectors/user';
import { selectSiteUrl, selectLoginUrl, selectLogoutUrl } from 'core/selectors/app';

import './SidebarWelcomeMessage.scss';

function MessageForIdentifiedUser({
  logoutUrl, userName, displayableUserId, sidebarTriggerClickHandler,
}) {
  return (
    <div className="mn_mobileNavWelcomeMessage">
      <button
        tabIndex="0"
        className="mn_closeHamburgerMenu mn_aaIconCircleX"
        aria-label="Close Menu"
        onClick={sidebarTriggerClickHandler}
      />
      <h3>
        Hello, <span>{userName}</span><br />
        <span className="mn_displayableUserId">AAdvantage&reg; number: {displayableUserId}</span>
      </h3>
      <p><a className="mn_button mn_logoutLink" href={logoutUrl}>Log out</a></p>
    </div>
  );
}

function MessageForNonIdentifiedUser({ loginUrl, sidebarTriggerClickHandler }) {
  return (
    <div className="mn_mobileNavWelcomeMessage">
      <button
        tabIndex="0"
        className="mn_closeHamburgerMenu mn_aaIconCircleX"
        aria-label="Close Menu"
        onClick={sidebarTriggerClickHandler}
      />
      <p>Already a member? <a className="mn_loginLink" href={loginUrl}>Login</a></p>
    </div>
  );
}

function SidebarWelcomeMessage({ sidebarTriggerClickHandler }) {
  const siteUrl = useSelector(selectSiteUrl);
  const userName = useSelector(selectUserName);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const loginUrl = useSelector(selectLoginUrl);
  const logoutUrl = useSelector(selectLogoutUrl);

  return userIsIdentified ?
    <MessageForIdentifiedUser
      siteUrl={siteUrl}
      logoutUrl={logoutUrl}
      userName={userName}
      displayableUserId={displayableUserId}
      sidebarTriggerClickHandler={sidebarTriggerClickHandler}
    />
    :
    <MessageForNonIdentifiedUser loginUrl={loginUrl} sidebarTriggerClickHandler={sidebarTriggerClickHandler} />;
}

export default SidebarWelcomeMessage;
